import { getStorageRef } from '@/plugins/firebase'
import {
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject
} from 'firebase/storage'

const IMAGE_STATE = {
  PAUSED: 'pause',
  RUNNING: 'running',
  ERROR: 'error',
  SUCCESS: 'success'
}

const _getPath = (folderName, imageType, id) => {
  if (imageType) {
    return `${folderName}/${imageType}/${id}`
  }

  return `${folderName}/${id}`
}

const ImageApi = {
  async uploadImage(folderName, fileData) {
    const { file, id, src } = fileData

    // 沒有檔案表示是拿 DB 拿到的資料，所以 Storage 一定有存
    if (!file) {
      return {
        state: IMAGE_STATE.SUCCESS,
        src
      }
    }

    const storageRef = getStorageRef(`${folderName}/${id}`)
    try {
      await uploadBytes(storageRef, file)

      try {
        const src = await getDownloadURL(storageRef)
        return {
          state: IMAGE_STATE.SUCCESS,
          src
        }
      } catch (error) {
        console.log('create download url error : ', error)
        return {
          state: IMAGE_STATE.ERROR,
          msg: error
        }
      }
    } catch (error) {
      console.log('uploadImage error : ', error)
      return {
        state: IMAGE_STATE.ERROR,
        msg: error
      }
    }
  },

  async deleteImage(folderName, imageType, id) {
    const storageRef = getStorageRef(_getPath(folderName, imageType, id))
    try {
      await deleteObject(storageRef)
      return {
        state: IMAGE_STATE.SUCCESS
      }
    } catch (error) {
      return {
        state: IMAGE_STATE.ERROR,
        message: error
      }
    }
  },

  async getImageListByFolder(folderName) {
    const listRef = getStorageRef(folderName)
    try {
      const res = await listAll(listRef)
      return {
        state: IMAGE_STATE.SUCCESS,
        list: res
      }
    } catch (error) {
      return {
        state: IMAGE_STATE.ERROR,
        msg: error
      }
    }
  }
}

export { IMAGE_STATE }
export default ImageApi
