<template>
  <div class="side-menu" :class="{ show: isShow, fix: isFix }" @click="onClose">
    <div class="side-menu__panel" @click.stop="onPanel">
      <MenuItem
        v-for="(item, index) in list"
        :key="`side_${index}_${item.title}`"
        :title="item.title"
        :titleColor="item.titleColor"
        :routerParam="item.routerParam"
        :list="item.list"
        @close="onClose"
      />
      <slot name="bottomContent" />
    </div>
  </div>
</template>

<script>
import MenuItem from '@/components/common/menu/MenuItem.vue'

export default {
  name: 'SideMenu',
  components: {
    MenuItem
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isFix: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onPanel() {},
    onClose() {
      this.$emit('update:isShow', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.side-menu {
  $animation-sec: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  background-color: rgba($color: #000, $alpha: 0.5);
  transition: visibility $animation-sec;

  &.show {
    visibility: initial;

    .side-menu__panel {
      transform: translateX(calc(100vw - 180px));
    }
  }

  &.fix {
    position: relative;
    width: fit-content;
    visibility: initial;
    background-color: initial;

    .side-menu__panel {
      transition: none;
      transform: translateX(0);
    }
  }

  .side-menu__panel {
    width: 180px;
    height: 100vh;
    padding: 32px 24px 16px 32px;
    background-color: $light-beige;
    transition: transform $animation-sec linear;
    transform: translateX(100vw);
    box-sizing: border-box;

    > * + * {
      margin-top: 26px;
    }
  }
}
</style>
