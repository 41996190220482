<template>
  <Icon width="25" height="25">
    <path d="M10 12h30v4H10z" />
    <path d="M10 22h30v4H10z" />
    <path d="M10 32h30v4H10z" />
  </Icon>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  name: 'IconMenu',
  components: {
    Icon
  }
}
</script>
