<template>
  <div class="loading">
    <div class="loading-wrap">
      <div class="loading__ball"></div>
      <div class="loading__text">NOW LOADING</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss" scoped>
.loading {
  $ballWidth: 20px;
  $ballHeight: 20px;
  $bounceHeight: 30px;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.8);

  .loading__ball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: $ballWidth;
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: $ballWidth;
      height: $ballHeight;
      border-radius: 50%;
      background-color: $green-and-blue;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
    }

    @keyframes bounce {
      0% {
        top: $bounceHeight;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
      }
      35% {
        height: $ballHeight;
        border-radius: 50%;
        transform: scaleX(1);
      }
      100% {
        top: 0;
      }
    }
  }

  .loading__text {
    color: $green-and-blue;
    display: inline-block;
    margin-left: 16px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.5px;
  }
}
</style>
