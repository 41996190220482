<template>
  <div class="menu-item">
    <div class="menu-item__title-wrap" @click="onTitle">
      <p class="title" :class="titleColor">{{ title }}</p>
      <IconArrowDown
        v-if="hasList"
        class="title-wrap__arrow"
        :class="{ open: isOpenList }"
      />
    </div>
    <div v-if="isOpenList" class="menu-item__list">
      <div
        v-for="item in list"
        :key="item.text"
        class="list__item"
        @click="onItem(item.routerParam)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowDown from '@/components/icons/IconArrowDown'
export default {
  name: 'MenuItem',
  components: {
    IconArrowDown
  },
  props: {
    title: {
      type: String,
      required: true
    },
    titleColor: {
      type: String,
      default: ''
    },
    routerParam: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpenList: false
    }
  },
  computed: {
    hasList() {
      return this.list.length > 0
    }
  },
  methods: {
    onTitle() {
      if (this.routerParam) {
        this.$router.push(this.routerParam)
        this.$emit('close')
      }

      if (this.hasList) {
        this.isOpenList = !this.isOpenList
      }
    },
    onItem(routerParam) {
      if (routerParam) {
        this.$router.push(routerParam)
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  .menu-item__title-wrap {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: $grey;
    }
  }

  .title {
    &.brown {
      color: $brown;
    }
  }

  .title-wrap__arrow {
    margin-left: 4px;
    transition: transform 0.1s linear;
    transform: rotate(0);

    &.open {
      transform: rotate(180deg);
    }
  }

  .menu-item__list {
    padding-left: 8px;
    margin-top: 8px;
    font-size: 16px;
    text-align: left;

    > * + * {
      margin-top: 8px;
    }
  }

  .list__item {
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      color: $grey;
    }
  }
}
</style>
