export const IMAGE_TYPE = {
  HDR: 'HDR',
  SD: 'SD'
}

export class ImageData {
  constructor(images, text, link, color) {
    this.images = {
      HDR: images.HDR || new Image(),
      SD: images.SD || new Image()
    }
    this.text = text || ''
    this.link = link || ''
    this.color = color || ''
  }

  setImageWithType(imageData, imageType) {
    this.images[imageType] = new Image(imageData)
  }
}

export class Image {
  constructor(data = {}) {
    const { id, src, name, file } = data
    this.id = id || (name && `${new Date().getTime()}_${name}`) || ''
    this.src = src || (file && URL.createObjectURL(file)) || ''
    this.name = name || ''
    this.file = file || {}
  }
}
