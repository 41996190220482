import { db } from '@/plugins/firebase'
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc
} from 'firebase/firestore/lite'
import { auth } from '@/plugins/firebase'
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import ImageAPI, { IMAGE_STATE } from './ImageAPI'

const firebaseGet = urlKey => {
  return getDocs(collection(db, urlKey))
}

const firebasePost = (urlKey, key, param) => {
  return setDoc(doc(db, urlKey, key), param)
}

const firebaseDelete = (urlKey, key) => {
  return deleteDoc(doc(db, urlKey, key))
}

const API = {
  checkLoginState(callback) {
    onAuthStateChanged(auth, user => {
      if (user) {
        callback({
          success: true,
          user
        })
      } else {
        callback({
          success: false,
          user: null
        })
      }
    })
  },
  login(account, password) {
    return signInWithEmailAndPassword(auth, account, password)
  },
  logout() {
    return signOut(auth)
  },
  async setBanners(files) {
    const result = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const { images, text, link, color } = file
      let upLoadImages = {}
      for (const [key, value] of Object.entries(images)) {
        const res = await ImageAPI.uploadImage(`banners/${key}`, images[key])
        if (res.state) {
          upLoadImages[key] = {
            ...value,
            src: res.src
          }

          delete upLoadImages[key]['file']
        }
      }
      const hasRes = Object.keys(upLoadImages)
      if (hasRes) {
        try {
          await firebasePost('banners', String(i), {
            images: upLoadImages,
            text,
            link,
            color
          })
          result.push({
            state: IMAGE_STATE.SUCCESS
          })
        } catch (error) {
          result.push({
            state: IMAGE_STATE.ERROR,
            msg: error
          })
        }
      }
    }

    return result
  },

  async getBanners() {
    const bannerDoc = await firebaseGet('banners')
    if (!bannerDoc) {
      return []
    }
    const bannerData = bannerDoc.docs.map(doc => doc.data())
    return bannerData
  },

  async deleteBanners(keys) {
    for (let i = 0; i < keys.length; i++) {
      await firebaseDelete('banners', keys[i])
    }
  }
}

export default API

// async function getCities() {
//   const citiesCol = collection(db, 'cities')
//   const citySnapshot = await getDocs(citiesCol)
//   const cityList = citySnapshot.docs.map(doc => doc.data())
//   return cityList
// }
