<template>
  <Icon width="25" height="25">
    <path d="M25 32.4l-9.7-9.7 1.4-1.4 8.3 8.3 8.3-8.3 1.4 1.4z" />
  </Icon>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  name: 'IconArrowDown',
  components: {
    Icon
  }
}
</script>
