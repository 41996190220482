<template functional>
  <svg
    class="icon-wrap"
    :width="props.width"
    :height="props.height"
    :viewBox="props.viewBox"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="nonzero"
    fill="currentColor"
    role="presentation"
    stroke="none"
    stroke-width="1"
    stroke-linecap="butt"
    stroke-linejoin="miter"
  >
    <slot />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    width: {
      type: [String, Number],
      default: 50
    },
    height: {
      type: [String, Number],
      default: 50
    },
    viewBox: {
      type: String,
      default: '0 0 50 50'
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-wrap {
  display: inline-block;
  vertical-align: baseline;
}
</style>
